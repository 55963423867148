import type { DataItemModel, ItemModel } from 'o365.modules.DataObject.Types.ts';
import type { DataObjectField } from 'o365.modules.DataObject.Fields.ts';

import { getJsonItem } from 'o365.modules.DataObject.extensions.JsonChange.ts';

/** Global JSON fields map */
const jsonFields: Map<string, Map<string, Map<string, string>>> = new Map();

export default class DataItem<T extends ItemModel = ItemModel> {
    private _initialized: boolean = false;
    protected _item: Partial<T> = {};
    protected _defaultValues: Partial<T> = {};
    protected _state: DataItemState;
    protected _onValueChanged?: (pKey: string, pNewValue: any, pOldValue: any, pItem: T, pRow: DataItemModel<T>) => void;
    protected _onSelected?: DataItemOptions<T>['onSelected'];
    protected _uniqueKeyField?: keyof T & string;
    /** DataObject fields used for JSON fields implementation  */
    protected _fields?: DataObjectField<keyof T>[];
    /** The index of this DataItem in Storage */
    index: number;
    /** Id of the DataObject this item belongs to */
    dataObjectId: string;
    /** App id of the DataObject this item belongs to */
    appId: string;
    /** Previous committed values */
    oldValues: Partial<T> = {};
    /** Current value of the item */
    get item() {
        return this._item as T;
    }
    /** Default values used in RecordSource */
    get defaultValues() { return this._defaultValues; }
    /** Indicates if the DataItem has any changes */
    get hasChanges() { return this._state.hasChanges; }
    set hasChanges(value: boolean) { this._state.hasChanges = value; }
    /** Error message of this item */
    get error() { return this._state.error; }
    /** Will be true when the item is loading. This applies only when the rows are dynamicly loaded */
    get isLoading() { return this._state.isLoading; }
    /** Will be true when the item is saving */
    get isSaving() { return this._state.isSaving; }
    /** Will be true when the item is being deleted */
    get isDeleting() { return this._state.isDeleting; }
    /** Indicates that the item is a new record with no changes */
    get isEmpty() { return this._state.isEmpty; }
    /** Indicates that the item is a new recrod */
    get isNewRecord() { return this._state.isNewRecord; }
    /** Indicates that the item is from BatchData extension */
    get isBatchRecord() { return this._state.isBatchRecord; }
    /** Promise that is resolved when this item has finished loading */
    get loadingPromise() { return this._state.loadingPromise; };
    /** Indicates that the item is selected. Used by SelectionControl */
    get isSelected() { return this._state.isSelected; }
    set isSelected(value) {
        this._state.isSelected = value;
        if (this._onSelected) {
            try {
                this._onSelected(this.index, value);
            } catch (ex) {
                console.error(ex);
            }
        }
    }
    /** Helper edit mode state */
    get isInEditMode() { return this._state.isInEditMode; }
    set isInEditMode(pValue) { this._state.isInEditMode = pValue; }

    disableSaving = false;
    /** State of this item. Should not be modified outside of DataObject modules  */
    get state() { return this._state; }
    get updateStates() { return this._updateStates; }
    /**
     * Unique key for this item. Will be PrimKey if not null, 
     * otherwise will fallback to the item index
     */
    get key() {
        return this._uniqueKeyField
            ? this.item[this._uniqueKeyField]
            : this.primKey ?? this.index;
    }
    /**
     * Unique field for the item (PrimKey, ID) 
     * provided by the DataObject
     */
    get uniqueKeyField() { return this._uniqueKeyField; }
    /** PrimKey of this item */
    get primKey() { return this._item['PrimKey']; }
    /** Map of json aliases from the DataObject this item belongs to */
    get jsonFields() {
        if (jsonFields.get(this.appId)?.has(this.dataObjectId)) {
            return jsonFields.get(this.appId)!.get(this.dataObjectId)!;
        } else {
            return undefined;
        }
    }
    /** Initialize setter of a key for this DataItem */
    get updateSetter() { return this._updateSetter; }
    /** Current changed values, will return null if there are no changes on the item */
    get changes() {
        if (!this.hasChanges) { return null; }
        const changes: Partial<T> = {};
        Object.keys(this.oldValues).forEach((key: keyof T) => { if (!key.toString().startsWith("_")) changes[key] = this._item[key]; });
        return changes;
    }
    /** Indicates that the item is current (index mathces with currentIndex) */
    get current() { return this._state.current; }
    set current(value) { this._state.current = value; }

    constructor(pIndex: number, pItem: T, pOptions: DataItemOptions<T>) {
        this.index = pIndex;
        this.dataObjectId = pOptions.dataObjectId;
        this.appId = pOptions.appId;
        this._onValueChanged = pOptions.onValueChanged;
        this._uniqueKeyField = pOptions.uniqueKeyField;

        if (pOptions.fields != null) {
            this._fields = pOptions.fields;
        }
        if (pOptions.onSelected) {
            this._onSelected = pOptions.onSelected;
        }

        this._state = new DataItemState({
            isNewRecord: false
        });

        this.extendItem(pItem);
    }

    /**
     * Initialize modules that depend on this item such as JSON fields. 
     * This should be called right after creating a new DataItem. 
     * Can be called only once.
     */
    initialize() {
        if (this._initialized) { return; }
        this._initialized = true;
        if (this._fields != null) {
            if (!jsonFields.has(this.appId)) {
                jsonFields.set(this.appId, new Map());
            }
            if (!jsonFields.get(this.appId)!.has(this.dataObjectId)) {
                jsonFields.get(this.appId)!.set(this.dataObjectId, new Map());
            }
            const dataObjectJsonFields = jsonFields.get(this.appId)!.get(this.dataObjectId)!;
            this._fields.filter(x => !!x.jsonAlias).forEach(field => {
                dataObjectJsonFields.set(field.name, field.jsonAlias!);
            });
        }
        if (this.jsonFields != null && this.jsonFields.size > 0) {
            Object.keys(this._item).forEach(key => {
                this._setJsonAliasField(key, this._item as T);
            });

            this.extendItem(this._item as T);
        }
    }

    /** Attempt to save this item */
    async save() {
        if (!this.hasChanges) { return []; }
        const { getDataObjectById } = await import('o365.vue.ts')
        const ds = getDataObjectById(this.dataObjectId, this.appId);
        return ds.save(this.index);
    }

    /** Attempt to delete this item */
    async delete() {
        const { getDataObjectById } = await import('o365.vue.ts')
        const ds = getDataObjectById(this.dataObjectId, this.appId);
        return ds.delete(this.index);
    }

    /**
     * Cancel all changes and reset the item state. 
     * If a key is provieed then will only cancel changes for that field.
     */
    cancelChanges(pKey?: keyof T & string) {
        if (pKey) {
            if (this.oldValues.hasOwnProperty(pKey)) {
                (this as any)[pKey] = this.oldValues[pKey];
            }
            if (Object.keys(this.oldValues).length === 0) {
                this.reset();
            }
        } else {
            Object.keys(this.oldValues).forEach(key => {
                (this as any)[key] = this.oldValues[key];
            });
            this.reset();
        }
    }

    /**
     * Clear current items and reset the state.
     * Any uncanceled changes will be treated as the new current values
     */
    reset() {
        this.oldValues = {};
        this._state.reset();
    }

    /**
     * Add new fields to the item for tracking changes. Will also resovle loading if 
     * the provided item has at least one property and the item is still in loading state.
     */
    extendItem(pItem: T) {
        if (this.isLoading && Object.keys(pItem).length > 0) {
            this._state.resolveLoad();
        }

        this._item = pItem;
        this._updateSetters();
    }

    /**
     * Update the error for the row. Can be either an Error 
     * object or a message string.
     */
    updateError(ex: Error | string | { error: string }) {
        this._state.isSaving = false;
        this._state.isDeleting = false;
        if (typeof ex === 'string') {
            this._state.error = ex;
        } else if (ex instanceof Error) {
            this._state.error = ex.message;
            if ((ex as any).errorType != null) {
                this._state.errorType = (ex as any).errorType;
            }
        } else if (ex?.error) {
            this._state.error = ex.error
        }
    }

    /** Remove the error for the row */
    removeError() {
        if (this._state.error) {
            this._state.error = null;
            this._state.errorType = null;
        }
    }

    updateOrExtendItem(pValues: Partial<T>) {
        Object.keys(pValues).forEach((key: keyof T & string) => {
            if (!this.hasOwnProperty(key)) {
                this.updateSetter(key);
            }
            this._item[key] = pValues[key];
        });
    }

    /** Add setters and getters for each property on the inner item */
    protected _updateSetters() {
        for (let key in this._item) {
            this._updateSetter(key);
        }
    }

    /** Add setter and getter for a property of the inner item */
    protected _updateSetter(pKey: keyof T & string) {
        if (this.hasOwnProperty(pKey)) { return; }
        if (this._item[pKey] !== null && !this._defaultValues.hasOwnProperty(pKey)) {
            this._defaultValues[pKey] = this._item[pKey];
        }

        const isDate = this._checkIfDate(pKey);
        const isDateTimeOffset = this._checkIfDateTimeOffset(pKey);
        Object.defineProperty(this, pKey, {
            get() { return this._item[pKey]; },
            set(value) {
                if (isDate && value) {
                    value = this._dropTimezoneInfo(value);
                } else if (isDateTimeOffset && value instanceof Date) {
                    const offset = this._getTimezoneOffset(value);
                    value = `${this._dropTimezoneInfo(value)}${offset}`;
                } else if (value instanceof Date) {
                    value = value.toISOString();
                }
                this._markChange(pKey, value);
            }
        });
    }

    protected _dropTimezoneInfo(pDate: Date) {
        if (pDate instanceof Date) {
            const formatDoubleDigits = (pNumber: number) => String(pNumber).padStart(2, '0');
            let dateString = `${pDate.getFullYear()}-`
                + `${formatDoubleDigits(pDate.getMonth() + 1)}-`
                + `${formatDoubleDigits(pDate.getDate())}T`
                + `${formatDoubleDigits(pDate.getHours())}:`
                + `${formatDoubleDigits(pDate.getMinutes())}:`
                + `${formatDoubleDigits(pDate.getSeconds())}`;
            const ms = pDate.getMilliseconds();
            dateString += `.${String(ms).padStart(3, '0')}`
            return dateString;
        }
        return pDate;
    }

    protected _getTimezoneOffset(pValue: Date) {
        const formatDoubleDigits = (pNumber: number) => String(pNumber).padStart(2, '0');
        const offset = pValue.getTimezoneOffset() * -1;
        const sign = offset >= 0 ? '+' : '-';
        const hours = formatDoubleDigits(Math.floor(offset / 60));
        const minutes = formatDoubleDigits(Math.floor(offset % 60));
        return `${sign}${hours}:${minutes}`;
    }

    protected _checkIfDate(pKey: string) {
        const field = this._fields?.find(x => x.name == pKey);
        if (field && field.dataType && ['date', 'datetime'].indexOf(field.dataType) > -1) { return true; }
        if (field && field.type && ['date'].indexOf(field.type) > -1) { return true; }
        return false;
    }
    protected _checkIfDateTimeOffset(pKey: string) {
        const field = this._fields?.find(x => x.name == pKey);
        return field?.dataType === 'datetimeoffset';
    }

    /** Track change of a property on the inner item */
    protected _markChange(pKey: keyof T & string, pValue: any) {
        let triggerValueChange = this.item[pKey] !== pValue;
        if (!this.oldValues.hasOwnProperty(pKey)) {
            this.oldValues[pKey] = this._item[pKey];
        }
        this._item[pKey] = pValue;
        if (pKey.startsWith('_') || pKey.startsWith('o_')) { return; }

        if (this.jsonFields?.has(pKey)) {
            this._setJsonAliasField(pKey, this._item as T);
        }

        this._state.error = null;
        this._state.errorType = null;
        this._updateStates();
        if (this._onValueChanged && triggerValueChange) {
            this._onValueChanged(pKey, pValue, this.oldValues[pKey], this._item as T, ((this as any) as DataItemModel<T>));
        }
    }

    /** Clear any old values that match with current values and update the item state */
    protected _updateStates() {
        const keys = Object.keys(this.oldValues);
        keys.forEach(key => {
            if (this.oldValues[key] === null && this._item[key] === '') {
                delete this.oldValues[key];
            } else if (this.oldValues[key] === this._item[key]) {
                delete this.oldValues[key];
            }
        });

        this._state.hasChanges = Object.keys(this.oldValues).length > 0;
        this._state.isEmpty = !this.hasChanges && this.isNewRecord;
    }

    protected _setJsonAliasField(pKey: keyof T & string, pItem: T) {
        if (this.jsonFields?.has(pKey)) {
            const jsonAlias = this.jsonFields.get(pKey)!;
            (pItem as any)[jsonAlias] = getJsonItem<T>(pItem[pKey], jsonAlias, pKey, this as any);
        }
    }
}

/** Helper class for storing DataItem state */
export class DataItemState {
    private _loadingPromise: Promise<boolean>;
    private _loadingResolve!: () => void;

    get loadingPromise() { return this._loadingPromise; }
    get resolveLoad() { return this._loadingResolve; }

    current = false;

    hasChanges = false;
    error: string | null = null;
    errorType: number | null = null;

    isLoading = true;
    isSaving = false;
    isDeleting = false;

    isNewRecord: boolean;
    isBatchRecord: boolean = false;
    isEmpty = false;
    isSelected = false;
    isInEditMode = false;

    constructor(pOptions: {
        isNewRecord: boolean
    }) {
        this.isNewRecord = pOptions.isNewRecord;
        this._loadingPromise = new Promise(res => this._loadingResolve = () => {
            this.isLoading = false;
            res(true);
        });
    }

    /** Set the state to the initial value */
    reset() {
        this.hasChanges = false;
        this.error = null;
        this.errorType = null;
        this.isSaving = false;
        this.isDeleting = false;
        this.isInEditMode = false;
    }
}

export type DataItemOptions<T extends ItemModel = ItemModel> = {
    dataObjectId: string,
    appId: string,
    fields?: DataObjectField<keyof T>[],
    uniqueKeyField?: string,
    onValueChanged?: (pKey: string, pNewValue: any, pOldValue: any, pItem: T, pRow: DataItemModel<T>) => void;
    onSelected?: (pIndex: number, pValue: boolean) => void;
};

/** Helper function for cleaning up JSON fields */
export function removeDataObjectJSONFields(pDataObjectId: string, pAppId: string) {
    if (jsonFields.get(pAppId)?.has(pDataObjectId)) {
        jsonFields.get(pAppId)!.delete(pDataObjectId);
    }
}
